<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('event.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-2 sm:p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end">
        <app-event-list-toolbar></app-event-list-toolbar>
      </div>

      <app-event-list-table ref="table" :filterInput="filterInput"></app-event-list-table>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import EventListToolbar from '@/views/event/event-list-toolbar.vue'
import EventListTable from '@/views/event/event-list-table.vue'

export default defineComponent({
  components: {
    [EventListToolbar.name]: EventListToolbar,
    [EventListTable.name]: EventListTable
  },
  data() {
    return {
      filterInput: []
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      listLoading: 'event/list/loading',
      destroyLoading: 'event/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    filterFields() {
      return [
        { label: this.i18n('event.fields.time'), value: 'time', type: 'date-range' }
      ]
    }
  },
  created() {
    document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.events')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.events')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []

      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }

      if (!this.filterInput.length) return
      await this.$refs.table.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.$refs.table.doFetchFirstPage()
    }
  }
})
</script>

<style lang="scss" scoped></style>
