<template>
  <div class="app-page-toolbar flex mt-5 sm:mt-0">
    <button v-if="hasPermissionToCreate"
      class="btn bg-theme-31 text-white sm:w-auto flex items-center gap-2"
      @click="$router.push('/events/new')"
    >
      <PlusIcon class="w-4 h-4" /> {{ i18n('common.create') }}
    </button>

    <!-- <button v-if="hasPermissionToPrint"
      id="tabulator-print"
      class="
        btn btn-outline-secondary
        dark:text-white
        w-1/2
        sm:w-auto
        mr-2
        ml-2
      "
      @click="onPrint"
    >
      <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
      {{ i18n('common.table.print') }}
    </button> -->

    <!-- <div class="dropdown w-1/2 sm:w-auto">
      <button v-if="hasPermissionToExport"
        class="
          dropdown-toggle
          btn btn-outline-secondary
          dark:text-white
          w-full
          sm:w-auto
        "
        aria-expanded="false"
      >
        <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
        {{ i18n('common.table.export') }}
        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
      </button>
      <div class="dropdown-menu w-40">
        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
          <a
            id="tabulator-export-xlsx"
            href="javascript:;"
            class="
              flex
              items-center
              block
              p-2
              transition
              duration-300
              ease-in-out
              bg-white
              dark:bg-dark-1
              hover:bg-gray-200
              dark:hover:bg-dark-2
              rounded-md
            "
            @click="onDownloadPdf"
          >
            <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.exportOptions.exportToPDF') }}
          </a>
          <a
            id="tabulator-export-html"
            href="javascript:;"
            class="
              flex
              items-center
              block
              p-2
              transition
              duration-300
              ease-in-out
              bg-white
              dark:bg-dark-1
              hover:bg-gray-200
              dark:hover:bg-dark-2
              rounded-md
            "
            @click="onExportXlsx"
          >
            <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.exportOptions.exportToXLSX') }}
          </a>
        </div>
      </div>
    </div> -->

    <!-- <div v-if="hasPermissionToDestroy || hasPermissionToChangeStatus"
        class="dropdown w-1/2 sm:w-auto"
        :class="isRTL ? 'lg:mr-2 mr-2' : 'lg:ml-2 ml-2'"
      >
        <button
          class="btn btn-outline-secondary w-full sm:w-auto dark:text-white"
          aria-expanded="false"
          :disabled="selected.length == 0"
          :class="selected.length > 0 ? 'dropdown-toggle' : ''"
        >
          {{ i18n('common.actions') }}
          <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
        </button>
        <div class="dropdown-menu w-40">
          <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
            <a v-if="hasPermissionToDestroy"
              href="javascript:;"
              data-toggle="modal"
              data-target="#delete-modal-preview"
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
                rounded-md
              "
            >
              <Trash2Icon class="w-4 h-4 mr-1 ml-1" />
              {{ i18n('common.delete') }}
            </a>
            <a v-if="hasPermissionToChangeStatus"
              href="javascript:;"
              @click="doEnableAllSelected()"
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
                rounded-md
              "
            >
              <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
              {{ i18n('common.activate') }}
            </a>
            <a v-if="hasPermissionToChangeStatus"
              href="javascript:;"
              @click="doDisableAllSelected()"
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
                rounded-md
              "
            >
              <LockIcon class="w-4 h-4 mr-1 ml-1" />
              {{ i18n('common.disable') }}
            </a>
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventPermissions } from './event-permissions'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'

export default {
  name: 'app-event-list-toolbar',

  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      listLoading: 'event/list/loading',
      destroyLoading: 'event/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    hasPermissionToRead() {
      return new EventPermissions(this.currentUser).read
    },
    hasPermissionToCreate() {
      return new EventPermissions(this.currentUser).create
    },
    hasPermissionToEdit() {
      return new EventPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new EventPermissions(this.currentUser).destroy
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'adminTable', 'events')
    },
    onDownloadPdf() {
      $h.downloadPdf('#adminTable', 'event', [1, 2, 8])
    },
    onPrint() {
      print({
        printable: 'adminTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 300,
        ignoreElements: [
          'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
