<template>
  <section class="app-content-table">
    <div ref="table" class="relative">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />

      <div class="overflow-x-auto">
        <table id="eventTable" ref="tableRef" class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap" id="">#</th>
              <th v-for="col in columns" :key="col.name" class="text-center whitespace-nowrap">
                {{ i18n(col.label) }}
              </th>
            </tr>
          </thead>

          <tbody v-if="rows.length">
            <tr v-for="(row, index) in rows" :key="index" class="intro-x">
              <td class="">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">{{ rowIndex(index) }}</div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterLocalization(row, 'title') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium" style="width: 250px;">
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium" style="width: 250px;">
                  {{ presenterLocalization(row, 'eventMission') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium" style="width: 250px;">
                  {{ presenterLocalization(row, 'eventVision') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium" style="width: 250px;">
                  {{ presenterLocalization(row, 'eventObjectives') }}
                </div>
              </td>
              <!-- <td class="text-center">
                <div class="font-medium">
                  {{ presenterLocalization(row, 'eventAgenda') }}
                </div>
              </td> -->
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterDate(row, 'time') }}
                </div>
                <div class="font-small whitespace-nowrap mt-1">
                  {{ presenterTime(row, 'time') }}
                </div>
              </td>
              <td class="table-report__action w-56" id="ignore-6">
                <div class="flex justify-center items-center gap-3 text-theme-24">
                  <router-link class="text-theme-10 dark:text-theme-30 whitespace-nowrap" :to="`/events/${row.id}/edit`" v-if="hasPermissionToEdit">
                    <Tippy tag="div" class="flex items-center gap-2 whitespace-nowrap" :content="isRTL ? 'انقر هنا لعرض الحضور' : 'Click here to view attendance'">
                      <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                    </Tippy>
                  </router-link>

                  <!-- <router-link class="text-theme-10 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap" :to="`/events/${row.id}`" v-if="hasPermissionToRead">
                    <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                  </router-link> -->

                  <router-link class="text-theme-10 dark:text-theme-30 whitespace-nowrap" :to="`/events/${row.id}/attendees`" v-if="hasPermissionToRead">
                    <Tippy tag="div" class="flex items-center gap-2 whitespace-nowrap" :content="isRTL ? 'انقر هنا لعرض الحضور' : 'Click here to view attendance'">
                      <UsersIcon class="w-4 h-4" /> {{ i18n('common.attendees') }}
                    </Tippy>
                  </router-link>

                  <button type="button" class="text-theme-24" v-if="!row.isRemoved" @click="showModal(row.id)">
                    <Tippy tag="div" class="flex items-center gap-2 whitespace-nowrap" :content="i18n('common.delete')">
                      <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                    </Tippy>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-empty-page v-if="!rows.length && !loading"
          :label="i18n('empty.event')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      ></Pagination>
    </div>

    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="event" @delete="doDestroy(selectedId)" @close="selectedId = null" />
    <!-- END: Delete Modal -->
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { EventPermissions } from './event-permissions'

export default defineComponent({
  name: 'app-event-list-table',
  props: {
    filterInput: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DeleteModal
  },
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectAll,
      tableRef
    }
  },
  data() {
    return {
      selectedId: null,
      columns: [
        {
          name: 'title',
          field: 'title',
          label: 'event.fields.title',
          align: 'center'
          // required: true,
        },
        {
          name: 'description',
          field: 'description',
          label: 'event.fields.description',
          align: 'center'
          // required: true,
        },
        {
          name: 'eventMission',
          field: 'eventMission',
          label: 'event.fields.eventMission',
          align: 'center'
          // required: true,
        },
        {
          name: 'eventVision',
          field: 'eventVision',
          label: 'event.fields.eventVision',
          align: 'center'
          // required: true,
        },
        {
          name: 'eventObjectives',
          field: 'eventObjectives',
          label: 'event.fields.eventObjectives',
          align: 'center'
          // required: true,
        },
        // {
        //   name: 'eventAgenda',
        //   field: 'eventAgenda',
        //   label: 'event.fields.eventAgenda',
        //   align: 'center'
        //   // required: true,
        // },
        {
          name: 'time',
          field: 'time',
          label: 'event.fields.time',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'event/list/rows',
      pagination: 'event/list/pagination',
      listLoading: 'event/list/loading',
      destroyLoading: 'event/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    hasPermissionToRead() {
      return new EventPermissions(this.currentUser).read
    },
    hasPermissionToCreate() {
      return new EventPermissions(this.currentUser).create
    },
    hasPermissionToEdit() {
      return new EventPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new EventPermissions(this.currentUser).destroy
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'event/list/doFetch',
      doFetchNextPage: 'event/list/doFetchNextPage',
      doFetchPreviousPage: 'event/list/doFetchPreviousPage',
      doMountTable: 'event/list/doMountTable',
      doChangeSort: 'event/list/doChangeSort',
      doChangePaginationCurrentPage: 'event/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'event/list/doChangePaginationPageSize',
      doDestroy: 'event/destroy/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterDateTime(row, fieldName) {
      return GenericModel.presenterDateTime(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter(el => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    showModal(id) {
      this.selectedId = id
      cash('#delete-modal-preview').modal('show')
    },
    async doFetchFirstPage(orderBy) {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy: orderBy || 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
