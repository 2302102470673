/* eslint-disable */
// import Permissions from '@/security/permissions';
import PermissionChecker from '@/security/permission-checker';

export class EventPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match('viewEvents');
    this.create = permissionChecker.match('createEvent');
    this.edit = permissionChecker.match('editEvent');
    this.destroy = permissionChecker.match('deleteEvent');

    // this.changeStatus = permissionChecker.match('changeEventStatus');
    // this.print = permissionChecker.match('printEventsTable');
    // this.export = permissionChecker.match('exportEventsTable');
  }
}
